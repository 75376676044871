<template>
  <tr>
    <td style="padding: 0 5px 0 5px; width: 5%; text-align: center">
      {{ productItem.stt }}
    </td>
    <td style="padding: 0 5px 0 5px; width: 22%">
      <div class="product-name-container">
        <p
          style="font-weight: 500"
          class="m-0"
          v-show="productItem.code !== ''"
        >
          ({{ productItem.code }})
        </p>
        <p class="text-product-name">
          {{ productItem.productName }}
          <span
            class="label font-weight-bold label-lg label-inline"
            v-bind:class="{
              'label-light-warning': productItem.merchantId === 1,
              'label-light-success': productItem.merchantId === 2,
            }"
            style="width: max-content"
          >{{ getMerchantName(productItem.merchantId) }}</span>
        </p>
        <div
          class="title-center"
          v-if="
            !productItem.imeiCode &&
              productItem.originalProductType === PRODUCT_TYPE.PRODUCT_IMEI
          "
        >
          <div
            class="mt-2 billCode title-center cursor-pointer"
            @click="linkToListImei"
          >
            <span style="color: blue">Danh sách IMEI</span>
          </div>
        </div>
        <p
          v-show="productItem.imeiCode"
          class="text-imei-no"
        >
          IMEI: {{ productItem.imeiCode }}
        </p>
        <div
          class="mt-2 d-table"
          v-if="productItem.newProductId"
        >
          <div class="d-table-row">
            <div class="d-table-cell pl-3 pr-2">
              <i class="fas fa-level-up-alt fa-rotate-90 pl-1"></i>
            </div>
            <div class="d-table-cell">
              <span
                class="d-flex"
                v-if="productItem.newProductCode"
              >({{ productItem.newProductCode }})</span>
              <span class="d-block font-weight-bolder">
                {{ productItem.newProductName }}
                <span
                  class="label font-weight-bold label-lg label-inline"
                  v-bind:class="{
                    'label-light-warning': productItem.merchantId === 1,
                    'label-light-success': productItem.merchantId === 2,
                  }"
                  style="width: max-content"
                >{{ getMerchantName(productItem.merchantId) }}</span>
                <p
                  v-if="productItem.newImeiCode"
                  class="text-imei-no"
                >
                  IMEI: {{ productItem.newImeiCode }}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td class="text-center">
      <div v-if="action === 'create'">
        <b-form-input
          tabindex="1"
          v-model="productItem.quantity"
          type="text"
          size="sm"
          placeholder="Nhập SL"
        ></b-form-input>
      </div>
      <span v-else>{{ productItem.quantity }}</span>
      <span
        class="d-flex"
        v-if="productItem.newQuantity > 0"
        style="margin-right: 1.3rem"
      >
        <i class="fas fa-level-up-alt fa-rotate-90 mr-2"></i>{{ formatMoney(productItem.newQuantity) }}</span>
    </td>
    <td style="text-align: right">
      {{ formatMoney(productItem.productPrice) }}
      <span
        class="d-flex justify-content-end"
        v-if="productItem.newProductPrice"
      >
        <i class="fas fa-level-up-alt fa-rotate-90 mr-2"></i>{{ formatMoney(productItem.newProductPrice) }}</span>
    </td>
    <td style="text-align: right">
      {{ formatMoney(productItem.totalPrice) }}
      <span
        class="d-flex justify-content-end"
        v-if="productItem.newTotalPrice"
      >
        <i class="fas fa-level-up-alt fa-rotate-90 mr-2"></i>{{ formatMoney(productItem.newTotalPrice) }}</span>
    </td>

    <td>
      <AutoSuggest
        :model="selectedWarrantyStatus"
        :suggestions="filteredWarrantyStatusOptions"
        placeholder="lý do bảo hành"
        :limit="10"
        @change="onInputWarrantyReason"
        @select="onSelected($event)"
      ></AutoSuggest>
    </td>
    <td class="text-center">
      <b-form-checkbox
        size="lg"
        v-model="productItem.isLostProduct"
        :disabled="isDisabledCheckStatus"
      ></b-form-checkbox>
    </td>
    <td
      class="text-center"
      v-if="
        checkWarrantyStatus !== WARRANTY_BILL_STATUS.DONE && action !== 'create'
      "
    >
      <span
        @click="changeProduct"
        class="change-product-icon cursor-pointer"
      >
        <em
          class="fas fa-sync-alt icon-color"
          style="font-size: 1rem;"
        ></em>
      </span>
    </td>
    <td
      style="width: 5%"
      v-show="isModeCreate"
    >
      <template>
        <div class="d-flex justify-content-center">
          <v-icon
            small
            class="text-danger"
            @click="deleteBillItem"
            v-b-tooltip
            title="Xóa"
          >mdi-delete</v-icon>
        </div>
      </template>
    </td>
  </tr>
</template>

<script>
import AutoSuggest from '@/view/base/auto-sugguest/AutoSuggest';
import { removeAccents } from '@/utils/common';
import Swal from 'sweetalert2';
import { WARRANTY_BILL_STATUS, PRODUCT_TYPE } from '@/utils/enum';

export default {
  props: {
    productItem: {
      type: Object,
      required: true,
    },
    warrantyReasons: {
      type: Array,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    checkWarrantyStatus: {
      type: Number,
    },
  },
  data() {
    return {
      PRODUCT_TYPE,
      WARRANTY_BILL_STATUS,
      selectedWarrantyStatus: this.productItem.warrantyReasonName || '',
      selectedWarrantyStatusId: this.productItem.warrantyReasonId || null,
      warrantyStatusOptions: this.warrantyReasons,
      filteredWarrantyStatusOptions: this.warrantyReasons,
    };
  },
  components: {
    AutoSuggest,
  },
  computed: {
    isDisabledCheckStatus() {
      return this.action !== 'create' && this.action !== 're-create';
    },
    isModeCreate() {
      return this.action === 'create' || this.action === 're-create';
    },
  },
  methods: {
    linkToListImei() {
      this.$emit('linkToListImei', {
        ...this.productItem,
        isMainProduct: true,
      });
    },
    changeProduct() {
      this.$emit('onChangeProduct', this.productItem);
    },
    getMerchantName(merchantId) {
      switch (merchantId) {
        case 1:
          return 'DDV';
        case 2:
          return 'TGMC';
      }
    },
    deleteBillItem() {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này khỏi hóa đơn không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          const deleteItem = {
            id: this.productItem.id,
            productId: this.productItem.productId,
          };
          this.$emit('deleteBillItem', deleteItem);
        }
      });
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    onSelected(option) {
      const data = {
        warrantyReasonId: option.item.id,
        warrantyReasonName: option.item.name,
        productItem: this.productItem,
      };
      this.$emit('onSelectWarranty', data);
    },
    onInputWarrantyReason(text) {
      this.selectedWarrantyStatus = text;
      const filteredData = this.warrantyStatusOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredWarrantyStatusOptions = [...filteredData];
      const data = {
        text: text,
        productItem: this.productItem,
      };
      this.$emit('onInputWarrantyReason', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-imei-no {
  font-size: 12px;
  font-style: italic;
  color: dodgerblue;
}

.text-product-name {
  margin-bottom: 0;
}

.product-name-container {
  margin: 5px;
}

.change-product-icon {
  color: rgb(63, 66, 84);
  font-size: 12px;
}
</style>
