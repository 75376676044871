<template>
  <div class="product-common-modal">
    <b-modal
      id="md-change-product"
      hide-footer
      title="Đổi sản phẩm mới"
      no-close-on-backdrop
      size="lg"
      static
    >
      <b-row class="mb-5">
        <b-col cols="3" class="pr-0">
          <b-form-select
            class="select-style"
            v-model="productSearch.searchType"
            :options="[
              {
                id: 1,
                name: 'Tìm sản phẩm',
              },
              {
                id: 2,
                name: 'Tìm sản phẩm imei',
              },
            ]"
            size="sm"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-col>
        <b-col cols="9" class="pl-0">
          <treeselect
            :load-options="loadOptionProducts"
            :multiple="false"
            :async="true"
            :placeholder="productSearch.placeHolder"
            :cache-options="false"
            retryText="Thử lại..."
            retryTitle="Nhấp thử lại"
            searchPromptText="Nhập để tìm kiếm..."
            loadingText="Đang tải..."
            noResultsText="Không có kết quả"
            openDirection="bottom"
            @select="onSelectProduct"
            :always-open="isOpenProductSearch"
            @search-change="onSearchChange"
          >
            <label
              slot="option-label"
              slot-scope="{ node, labelClassName }"
              :class="labelClassName"
            >
              <template>
                <div>
                  <b class="text-primary"
                    >( {{ convertPrice(node.raw.sellingPrice) }} )</b
                  >
                  <b
                    :class="
                      node.raw.quantityInStock > 0
                        ? 'text-success'
                        : 'text-danger'
                    "
                  >
                    ( TCB:{{ node.raw.quantityInStock }} )
                  </b>

                  {{ node.raw.label }}
                  <span
                    v-if="
                      node.raw.imeiCode && node.raw.imeiCode.trim().length > 0
                    "
                    class="text-warning"
                  >
                    - IMEI:
                    {{ node.raw.imeiCode }}
                  </span>
                </div>
              </template>
            </label>
          </treeselect>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <table class="table table-bordered table-vertical-center table-hover">
            <thead>
              <tr>
                <th class="header-table">Sản phẩm</th>
                <th class="header-table">Số lượng</th>
                <th class="header-table">Giá</th>
                <th class="header-table">Thành tiền</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>
                    {{ selectedProduct ? selectedProduct.productName : '' }}
                  </p>
                  <p
                    v-if="
                      selectedProduct &&
                        selectedProduct.newProductType === 2 &&
                        selectedProduct.newImeiCode
                    "
                    class="text-imei"
                  >
                    IMEI: {{ selectedProduct.newImeiCode }}
                  </p>
                  <b-input-group
                    class="row col-8 btn-imei"
                    v-if="
                      (selectedProduct &&
                        selectedProduct.newProductType === 2) ||
                        currentProductType === 2
                    "
                  >
                    <div>
                      <div
                        class="mt-2 billCode"
                        style="cursor: pointer"
                        @click="linkToListImei"
                      >
                        <span style="color: blue">Danh sách IMEI</span>
                      </div>
                    </div>
                  </b-input-group>
                </td>
                <td>
                  <div>
                    <b-form-input
                      tabindex="1"
                      @input="onChangeQuantity"
                      v-model="selectedProduct.quantity"
                      type="text"
                      size="sm"
                      placeholder="Nhập SL"
                    ></b-form-input>
                  </div>
                </td>
                <td>
                  {{
                    selectedProduct
                      ? formatMoney(selectedProduct.productPrice)
                      : 0
                  }}
                </td>
                <td>
                  {{
                    selectedProduct
                      ? formatMoney(selectedProduct.totalPrice)
                      : 0
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>

      <b-overlay
        :show="onLoadingList || isBusy"
        rounded
        opacity="0.6"
        class="d-inline-block mr-3"
      >
        <template #overlay>
          <div class="d-flex align-items-center">
            <b-spinner variant="light" small type="grow"></b-spinner>
          </div>
        </template>
        <b-button
          :disabled="onLoadingList || !selectedProduct"
          variant="primary"
          size="sm"
          @click="onSave"
        >
          <strong>Cập nhật</strong>
        </b-button>
      </b-overlay>

      <b-button
        style="width: 80px"
        variant="secondary"
        size="sm"
        @click="$bvModal.hide('md-change-product')"
      >
        <strong>Hủy</strong>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from '@/core/services/event-bus';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { PRODUCT_TYPE } from '@/utils/enum';
import ApiService from '../../../core/services/api.service';
import { cloneDeep, makeToastFaile } from '../../../utils/common';
import { convertPrice } from '@/utils/common';

const SEARCH_TYPE = {
  PRODUCT: 1,
  IMEI: 2,
};

export default {
  components: {
    Treeselect,
  },
  mounted() {
    EventBus.$on('popup-product-common', this.popupProductCommon);
  },
  data() {
    return {
      isOpenProductSearch: false,
      PRODUCT_TYPE,
      searchProduct: '',
      productSearch: {
        searchType: SEARCH_TYPE.PRODUCT,
        placeHolder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        searchName: null,
      },
      onLoadingList: false,
      isBusy: false,
      storeId: null,
      selectedProduct: {
        quantity: 0,
      },
      currentProductType: null,
      productSearchTemp: null,
    };
  },
  methods: {
    linkToListImei: function() {
      this.$emit('linkToListImei', {
        ...this.selectedProduct,
        isMainProduct: false,
      });
    },
    onChangeQuantity() {
      this.selectedProduct.totalPrice =
        this.selectedProduct.quantity * this.selectedProduct.productPrice;
    },
    convertPrice,
    onSearchChange(searchQuery) {
      if (searchQuery.length > 1 && !this.isOpenProductSearch) {
        this.isOpenProductSearch = true;
      } else if (searchQuery.length === 0) {
        this.isOpenProductSearch = false;
      }
    },
    onSelectProduct(node) {
      this.isOpenProductSearch = false;
      const itemSelected = cloneDeep(node);
      this.selectedProduct = {
        oldId: this.selectedProduct.oldId,
        oldProductId: this.selectedProduct.oldProductId,
        newId: itemSelected.id,
        newProductId: itemSelected.productId,
        productName: itemSelected.productName,
        productCode: itemSelected.productCode,
        productPrice: itemSelected.sellingPrice,
        totalPrice: 1 * itemSelected.sellingPrice,
        productType: itemSelected.productType,
        newProductType: itemSelected.productType,
        originalProductType: itemSelected.productType,
        quantity: 1,
        sellingPrice: itemSelected.sellingPrice,
      };
      this.$emit('onSelectWarrantyProduct', this.selectedProduct);
    },
    fetchProduct(textSearch) {
      this.listProduct = [];

      const params = {
        searchProduct: textSearch ? textSearch.trim() : null,
        storeId: this.storeId || null,
      };

      return ApiService.query('productSearch', { params }).then((response) => {
        return response.data;
      });
    },
    fetchProductImei(textSearch) {
      this.listProduct = [];
      const storeId = this.storeId || null;

      if (!storeId) {
        makeToastFaile('Chưa chọn cửa hàng');
        return [];
      }

      const params = {
        imeiCode: textSearch ? textSearch.trim() : null,
        storeId: storeId,
      };

      return ApiService.query(`productSearch/search-by-imei`, { params }).then(
        (response) => {
          return response.data;
        },
      );
    },
    searchProductAPI(searchQuery, type) {
      let products = [];
      switch (type) {
        case SEARCH_TYPE.PRODUCT:
          products = this.fetchProduct(searchQuery, type);
          break;
        case SEARCH_TYPE.IMEI:
          products = this.fetchProductImei(searchQuery, type);
          break;
        default:
          break;
      }
      return products;
    },
    async loadOptionProducts({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        this.productSearchTemp = searchQuery;
        const type = this.productSearch.searchType;
        this.productSearchTemp = searchQuery;
        const result = await this.searchProductAPI(searchQuery, type);
        const optionProduct = (result.data || []).map((element) => {
          if (type === PRODUCT_TYPE.PRODUCT) {
            return {
              id: this.$uuid.v4(),
              label: element.productName,
              ...element,
            };
          } else {
            return {
              id: this.$uuid.v4(),
              label: element.productName,
              imeiCode: element.imeiCode,
              ...element,
            };
          }
        });
        callback(null, optionProduct);
      }
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    popupProductCommon(payload) {
      const { storeId, product } = payload;
      this.storeId = storeId;
      this.selectedProduct = {
        oldId: product.id || null,
        oldProductId: product.productId || null,
        newId: product.newId || null,
        newProductId: product.newProductId || null,
        newProductType: product.newProductType || null,
        productName: product.newProductName || '',
        quantity: product.newQuantity || 0,
        productPrice: product.newProductPrice || 0,
        totalPrice: product.newTotalPrice || 0,
        originalProductType: product.originalProductType || 0,
        imeiCode: product.imeiCode || '',
        newImeiCode: product.newImeiCode || '',
      };
      this.$bvModal.show('md-change-product');
    },
    onSave() {
      this.$bvModal.hide('md-change-product');
      this.$emit('changeWarrantyProduct', this.selectedProduct);
    },
  },
};
</script>

<style lang="scss">
.product-common-modal {
  .modal-backdrop {
    opacity: 0.5;
  }
  .vue-treeselect__control {
    height: 33px;
  }
  .header-table {
    color: rgb(24, 28, 50);
  }
  .text-imei {
    font-size: 12px;
    font-style: italic;
    color: dodgerblue;
  }
}
</style>
