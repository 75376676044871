<template>
  <div class="upsert-warranty-bill">
    <KTCodePreview v-bind:title="pageTitle">
      <template v-slot:preview>
        <b-form
          ref="form"
          lazy-validation
          class="row"
        >
          <!-- Main content -->
          <b-container class="bv-example-row">
            <b-row>
              <!-- Left side content -->
              <b-col md="9">
                <p class="text-list-product">Thông tin sản phẩm</p>
                <!-- product table -->
                <b-row class="mb-2">
                  <b-col cols="3">
                    <b-form-select
                      class="select-style"
                      v-model="selectTypeSearch"
                      :options="listTypeSearch"
                      size="sm"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </b-col>
                  <b-col
                    cols="9"
                    class="pl-0"
                  >
                    <AutoSuggest
                      :model="searchProduct"
                      :suggestions="filteredOptionsProduct"
                      @select="onSelectedProduct"
                      @change="onInputSearchProduct"
                      placeholder="tên sản phẩm"
                      :limit="10"
                    >
                      <template #custom="{ suggestion }">
                        <div>
                          <div class="d-flex flex-column">
                            <span class="text-primary mb-1">{{
                              suggestion.item.productName
                            }}</span>
                          </div>
                        </div>
                      </template>
                    </AutoSuggest>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <table class="table table-bordered table-vertical-center table-hover">
                      <thead>
                        <tr>
                          <th class="header-table text-center">
                            STT
                          </th>
                          <th class="header-table">Sản phẩm</th>
                          <th class="header-table column-quantity">SL</th>
                          <th class="header-table">Giá</th>
                          <th class="header-table">Thành tiền</th>
                          <th class="header-table">Lý do bảo hành</th>
                          <th class="header-table column-import-stock">Mất xác</th>
                          <th
                            class="header-table column-swap-product"
                            v-if="
                              checkWarrantyStatus !==
                                WARRANTY_BILL_STATUS.DONE && action !== 'create'
                            "
                          >
                            Đổi sp
                          </th>
                          <th v-show="action === 'create' || action === 're-create'"></th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="item in listBillItem"
                        :key="item.id + item.productId"
                      >
                        <WarrantyBillItem
                          v-bind:productItem="item"
                          v-bind:action="action"
                          v-bind:warrantyReasons="filterReasonByCate(item)"
                          v-bind:checkWarrantyStatus="checkWarrantyStatus"
                          v-on:deleteBillItem="deleteBillItem"
                          v-on:onSelectWarranty="onSelectWarranty"
                          v-on:onInputWarrantyReason="onInputWarrantyReason"
                          v-on:onChangeProduct="onChangeProduct"
                          v-on:linkToListImei="linkToListImei"
                          ref="billItemRef"
                        />
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </b-col>
                </b-row>
                <!-- user information -->
                <b-row></b-row>

                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Điện thoại<b class="text-danger">*</b> </label>
                      <AutoSuggest
                        :model="mainModel.customerPhone"
                        :suggestions="filteredOptionsCustomer"
                        :suggestionName="'phoneNo'"
                        placeholder="số điện thoại"
                        :limit="10"
                        @select="onSelectCustomer"
                        @change="onSearchChange"
                      >
                        <template #custom="{ suggestion }">
                          <div>
                            <div class="d-flex flex-column">
                              <span
                                class="text-primary mb-1"
                                v-if="suggestion.item.phoneNo"
                              >({{ suggestion.item.phoneNo }})</span>
                              <span v-if="suggestion.item.fullName">{{
                                suggestion.item.fullName
                              }}</span>
                              <span v-else>{{ suggestion.item.message }}</span>
                            </div>
                          </div>
                        </template>
                      </AutoSuggest>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Nhân viên bảo hành<b class="text-danger">*</b></label>
                      <AutoSuggest
                        :suggestions="filteredEmployeeOptions"
                        :model="mainModel.employeeName"
                        :placeholder="'nhân viên bảo hành'"
                        :limit="10"
                        @change="onInputEmployee"
                        @select="onSelectEmployee"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Họ tên khách hàng<b class="text-danger">*</b></label>
                      <b-form-input
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="mainModel.customerName"
                        placeholder="Họ tên khách hàng"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group :disabled="isDisableStatus">
                      <label>Trạng thái bảo hành:</label>
                      <b-form-select
                        :options="[
                          {
                            id: null,
                            name: 'Chọn trạng thái',
                            disabled: true,
                          },
                          {
                            id: 1,
                            name: 'Mới',
                            disabled: listStatus.includes(optionValue.new)
                              ? false
                              : true,
                          },
                          {
                            id: 2,
                            name: 'Đang xử lý',
                            disabled: listStatus.includes(
                              optionValue.processing,
                            )
                              ? false
                              : true,
                          },
                          {
                            id: 3,
                            name: 'Đã trả bảo hành',
                          },
                        ]"
                        class="select-style"
                        size="sm"
                        v-model="mainModel.warrantyStatus"
                        value-field="id"
                        text-field="name"
                        placeholder="Trạng thái bảo hành"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Địa chỉ</label>
                      <b-form-input
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="mainModel.customerAddress"
                        placeholder="Địa chỉ khách hàng"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4"> </b-col>
                </b-row>

                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Ghi chú bảo hành:</label>
                      <b-form-textarea
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="mainModel.warrantyNote"
                        placeholder="Ghi chú bảo hành"
                        :rows="6"
                        :max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Ghi chú nội bộ</label>
                      <b-form-textarea
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="mainModel.internalNote"
                        placeholder="Ghi chú nội bộ"
                        :rows="6"
                        :max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Right side content -->
              <b-col md="3">
                <p class="text-payment">Thanh toán</p>
                <b-form-group>
                  <label>Cửa hàng:</label>
                  <AutoSuggest
                    :suggestions="filteredStoreOptions"
                    :model="mainModel.storeName"
                    :placeholder="'cửa hàng'"
                    :limit="10"
                    @change="onInputStore"
                    @select="onSelectStore"
                  />
                </b-form-group>
                <b-form-group>
                  <label>Tiền mặt</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="mainModel.cashAmount"
                    placeholder="Nhập số tiền mặt"
                    v-mask="mask"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-show="checkCashAmount()">
                  <label>Tài khoản tiền mặt:</label>
                  <AutoSuggest
                    :model="mainModel.cashAccountName"
                    :suggestions="filteredCashAccOptions"
                    placeholder="tài khoản tiền mặt"
                    :limit="10"
                    @select="onSelectCash"
                    @change="onChangeCash"
                  >
                    <template #custom="{ suggestion }">
                      <div>
                        <span>{{ suggestion.item.name }}</span>
                      </div>
                    </template>
                  </AutoSuggest>
                </b-form-group>
                <b-form-group>
                  <label>Tiền chuyển khoản</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="mainModel.transferAmount"
                    placeholder="Nhập số tiền chuyển khoản"
                    v-mask="mask"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-show="checkTransferAmount()">
                  <label for="input-transfer-account">Tài khoản chuyển khoản:</label>
                  <AutoSuggest
                    :model="mainModel.transferAccountName"
                    :suggestions="filteredTransferOptions"
                    placeholder="tài khoản chuyển khoản"
                    :limit="10"
                    @select="onSelectedTransfer"
                    @change="onInputChangeTransfer"
                  >
                    <template #custom="{ suggestion }">
                      <div>
                        <span>{{ suggestion.item.name }}</span>
                      </div>
                    </template>
                  </AutoSuggest>
                </b-form-group>
                <b-form-group v-if="checkTransferAmount()">
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="mainModel.customerBankName"
                    placeholder="Ngân hàng của khách"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-if="checkTransferAmount()">
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="mainModel.customerTransferNo"
                    placeholder="STK của khách"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </template>
      <!-- page footer -->
      <template v-slot:foot>
        <b-container class="bv-example-row">
          <b-row>
            <b-button
              tabindex="15"
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="debounceSubmit"
            >Lưu</b-button>
            <b-button
              tabindex="16"
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="onClickCancel"
            >Hủy</b-button>
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
    <template>
      <ChangeWarrantyProduct
        @changeWarrantyProduct="changeWarrantyProduct"
        @onSelectWarrantyProduct="onSelectWarrantyProduct"
        @linkToListImei="linkToListImei"
      />
    </template>
    <b-modal
      ref="list-imei-modal"
      hide-footer
      :title="modalImeiTitle"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <label
          class="labelInput"
          for="inputOriginalPrice"
        >Kho: {{ mainModel.storeName }}</label>
        <b-table
          :items="listImeiByProduct"
          :fields="fieldListImei"
          class="table-bordered table-hover col-md-12"
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table
              :speed="5"
              :animate="true"
              :columns="3"
            ></vcl-table>
          </template>
          <template v-slot:cell(imeiCode)="row">
            <div class="d-flex justify-content-center">
              <span v-text="row.item.imeiCode"></span>
            </div>
          </template>
          <template v-slot:cell(sellingPrice)="row">
            <div>
              <span
                v-text="convertPrice(row.item.sellingPrice)"
                class="text-right"
              ></span>
            </div>
          </template>
          <template v-slot:cell(status)="row">
            <div class="d-flex justify-content-center">
              <span
                v-text="getStatusName(row.item.status)"
                class="label font-weight-bold label-lg label-inline"
                v-bind:class="{
                  'label-light-warning': row.item.status === 1,
                  'label-light-success': row.item.status === 2,
                  'label-light-danger': row.item.status === 3,
                  'label-light-default': row.item.status === 4,
                }"
              ></span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <v-icon
                small
                class="text-danger"
                @click="applyImei(row.item)"
                v-b-tooltip
                title="Áp dụng"
              >mdi-pen</v-icon>
            </div>
          </template>
        </b-table>
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModalListImei"
        >Hủy</b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import KTCodePreview from '@/view/content/CodePreview.vue';
import WarrantyBillItem from '@/view/components/bills/WarrantyBillItem.vue';
import ChangeWarrantyProduct from '@/view/components/bills/ChangeWarrantyProduct.vue';
import { currencyMask } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import {
  PRODUCT_TYPE,
  WARRANTY_BILL_STATUS,
  BILL_ITEM_TYPE,
  PAYMENT_TYPE,
  WARRANTY_ERROR_BY,
} from '@/utils/enum';
import { unMaskPrice } from '@/utils/common';
import { cloneDeep } from 'lodash';
import AutoSuggest from '@/view/base/auto-sugguest/AutoSuggest';
import {
  isEmptyAccountingAccount,
  removeAccents,
  convertPrice,
} from '@/utils/common';
import { getToastInstance } from '@/utils/toastHelper';
import { debounce } from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import { EventBus } from '@/core/services/event-bus';
import { getData } from '../../../../utils/saveDataToLocal';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    KTCodePreview,
    WarrantyBillItem,
    ChangeWarrantyProduct,
    AutoSuggest,
  },
  computed: {
    pageTitle() {
      return `Hóa đơn bảo hành${
        this.action === 'update' ? ` #${this.billNumber}` : ''
      }`;
    },
    isDisableStatus() {
      return (
        this.checkWarrantyStatus === WARRANTY_BILL_STATUS.DONE ||
        (this.checkWarrantyStatus === WARRANTY_BILL_STATUS.NEW &&
          ['create', 're-create'].includes(this.action))
      );
    },
  },
  data() {
    return {
      WARRANTY_ERROR_BY,
      selectTypeSearch: 1,
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
      ],
      optionsProduct: [],
      filteredOptionsProduct: [],
      searchProduct: '',
      optionsCustomer: [],
      filteredOptionsCustomer: [],
      onLoading: false,
      fieldListImei: [
        {
          key: 'imeiCode',
          label: 'IMEI',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        { key: 'actions', label: '' },
      ],
      modalImeiTitle: '',
      WARRANTY_BILL_STATUS,
      mask: currencyMask,
      billNumber: null,
      billId: null,
      action: '',
      checkWarrantyStatus: 1,
      mainModel: {
        employeeStore: '',
        storeId: null,
        cashAmount: 0,
        cashId: null,
        cashAccountId: null,
        cashAccountName: '',
        cashAccountCode: null,
        transferAmount: 0,
        transferId: null,
        transferAccountName: '',
        transferAccountId: null,
        transferAccountCode: null,
        customerId: '',
        customerName: '',
        customerBankName: '',
        customerTransferNo: '',
        employeeName: '',
        customerPhone: '',
        customerAddress: '',
        warrantyNote: '',
        warrantyStatus: 1,
        internalNote: '',
        saleId: null,
        accessoryStaffId: null,
        accessoryStaffName: '',
        assistantId: null,
        assistantName: '',
        cashierId: null,
        cashierName: '',
        codeShip: '',
        companyId: null,
        companyName: '',
        contractCode: null,
        contractDate: null,
        orderId: null,
        billType: null,
        isOnTransfer: false,
      },
      warrantyReasons: [],
      listWarrantyStatus: [
        {
          id: null,
          name: 'Chọn trạng thái',
          disabled: true,
        },
        {
          id: 1,
          name: 'Mới',
          disabled: this.isAllowToUpdateStatus,
        },
        {
          id: 2,
          name: 'Đang xử lý',
          disabled: this.isAllowToUpdateStatus,
        },
        {
          id: 3,
          name: 'Đã trả bảo hành',
        },
      ],
      listBillItem: [],
      selectedCashAccount: '',
      filteredCashAccOptions: [],
      cashAccOptions: [],
      filteredTransferOptions: [],
      transferOptions: [],
      listStatus: [],
      optionValue: {
        new: 1,
        processing: 2,
        done: 3,
      },
      listImeiByProduct: [],
      applyImeiForItemId: null,
      selectingProduct: {},
      isNew: true,
      storeOptions: [],
      listStore: [],
      filteredStoreOptions: [],
      errorMessage: '',
      listEmployee: [],
      employeeOptions: [],
      filteredEmployeeOptions: [],
      warrantyErrorBy: WARRANTY_ERROR_BY.BILL,
    };
  },
  async created() {
    await this.fetchWarrantyReasons();
    if (this.action === 'create' || this.action === 're-create') {
      this.setEmployeeInfo();
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn', route: '/' },
      { title: 'Danh sách hóa đơn', route: '/' },
      { title: 'Thông tin hóa đơn bảo hành', route: '/' },
    ]);
    this.billNumber = this.$route.query.billId;
    this.action = this.$route.query.action;
    this.warrantyErrorBy =
      this.$route.query.warrantyErrorBy || this.WARRANTY_ERROR_BY.BILL;
    await this.fetchListStoreByUser();
    await this.getListEmployee();
    if (this.billNumber) {
      await this.fetchBillById();
    }

    this.fetchListTransferAccountantByType();
  },
  methods: {
    ...getToastInstance(),
    debounceProductInput: debounce(function () {
      const textSearch = this.searchProduct ? this.searchProduct.trim() : '';
      this.fetchProduct(textSearch);
    }, TIME_TRIGGER),
    fetchProduct(textSearch) {
      ApiService.get(
        `productSearch?storeId=${this.mainModel.storeId}&searchProduct=${textSearch}`,
      ).then(({ data }) => {
        this.optionsProduct = [];
        this.filteredOptionsProduct = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct.push(element);
        });

        this.filteredOptionsProduct = [...this.optionsProduct];
      });
    },
    onSelectedProduct(option) {
      const product = option.item;

      if (product.productType === PRODUCT_TYPE.PRODUCT_COMBO) {
        return this.makeToastFailure('Không hỗ trợ nhập kho sản phẩm combo');
      }

      const {
        productId,
        productName,
        productCode,
        sellingPrice,
        productType,
        merchantId,
      } = product;

      const productDTO = {
        stt: this.listBillItem.length + 1,
        id: uuidv4(),
        productId: productId,
        productName: productName,
        code: productCode,
        productPrice: sellingPrice,
        productType: BILL_ITEM_TYPE.PRODUCT,
        returnPrice: 0,
        quantity: 1,
        imeiCode: '',
        totalPrice: sellingPrice,
        originalProductType: productType,
        originalQuantity: 1,
        merchantId: merchantId || 1,
        warrantyReasonId: null,
        warrantyReasonName: '',
        belongToProductId: null,
        isGiftTaken: true,
        newId: '',
        newProductId: null,
        newProductName: '',
        newProductCode: '',
        newQuantity: 0,
        newProductPrice: 0,
        newTotalPrice: 0,
        newProductType: null,
      };

      this.listBillItem.push(productDTO);
      this.searchProduct = '';
    },
    onInputSearchProduct(text = '') {
      this.searchProduct = text;
      this.debounceProductInput();
    },
    getCustomerInfoById(customerId) {
      if (customerId) {
        ApiService.get(`customer/${customerId}`).then((response) => {
          const customerInfo = response.data.data;
          this.mainModel.customerName = customerInfo.fullName;
          this.mainModel.customerAddress = customerInfo.address;
        });
      }
    },
    debouncePhoneInput: debounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    getListCustomer() {
      const text = this.mainModel.customerPhone
        ? this.mainModel.customerPhone.trim()
        : null;
      if (!text) {
        return;
      }
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          this.optionsCustomer = data.data.data;
          if (this.optionsCustomer.length > 0) {
            this.filteredOptionsCustomer = [...this.optionsCustomer];
          } else {
            this.filteredOptionsCustomer = [
              { message: 'SĐT không có trong hệ thống', phoneNo: text },
            ];
          }
        },
      );
    },
    onSearchChange(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.mainModel.customerPhone = searchQuery;
      this.optionsCustomer = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer];
      this.debouncePhoneInput();
    },
    onSelectCustomer(option) {
      this.mainModel.customerId = option.item.id;
      this.mainModel.customerPhone = option.item.phoneNo;
      this.getCustomerInfoById(option.item.id);
    },
    filterReasonByCate(product) {
      const { productWebCategory } = product;
      if (!productWebCategory) return this.warrantyReasons;

      const filteredReasons = this.warrantyReasons.filter((item) => {
        return item.internalCateId === productWebCategory;
      });

      return filteredReasons;
    },
    getListEmployee: async function () {
      const storeIds = this.storeOptions.map((element) => element.id);
      await ApiService.get(
        `employees/get-by-store?employeeName=&storeId=${storeIds.join(',')}`,
      ).then((data) => {
        const employees = data.data.data;
        this.listEmployee = employees;
        this.employeeOptions = employees.map((element) => {
          return {
            id: element.id,
            name: element.fullName,
          };
        });
        this.filteredEmployeeOptions = [...this.employeeOptions];
      });
    },
    fetchListStoreByUser: async function () {
      await ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          if (data.data.jobTitleId === 6) {
            this.mainModel.cashierName = data.data.employeeName;
          }
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.mainModel.storeId = stores[0].id;
            this.mainModel.storeName = stores[0].name;
            this.storeOptions = [...stores];
          } else {
            this.storeOptions = [];
            this.mainModel.storeId = stores[0].id;
            this.mainModel.storeName = stores[0].name;
            stores.map((element) => {
              let item = {
                id: element.id,
                suggestionName: element.name,
                name: element.name,
              };
              this.storeOptions.push(item);
            });
            this.filteredStoreOptions = [...this.storeOptions];
          }
        }
      });
    },
    onInputStore(text) {
      this.mainModel.storeName = text;
      const filteredData = this.storeOptions.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredStoreOptions = [...filteredData];
    },
    onSelectStore(option) {
      this.mainModel.storeId = option.item.id;
      this.mainModel.storeName = option.item.name;
    },
    onInputEmployee(text) {
      this.mainModel.employeeName = text;
      const filteredData = this.employeeOptions.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredEmployeeOptions = [...filteredData];
    },
    onSelectEmployee(option) {
      this.mainModel.saleId = option.item.id;
      this.mainModel.employeeName = option.item.name;
    },
    applyImei(item) {
      const { id, oldId, isMainProduct } = this.selectingProduct;

      const updateIndex = this.listBillItem.findIndex(
        (item) => item.id === (isMainProduct ? id : oldId),
      );
      if (updateIndex === -1) return;
      const updateProduct = this.listBillItem[updateIndex];
      updateProduct[isMainProduct ? 'imeiCode' : 'newImeiCode'] = item.imeiCode;

      this.hideModalListImei();
    },
    getStatusName: function (statusType) {
      switch (statusType) {
        case 1:
          return 'Mới';
        case 2:
          return 'Đã bán';
        case 3:
          return 'Đang vận chuyển';
        case 4:
          return 'Lỗi';
        case 5:
          return 'Đã trả NCC';
        case 6:
          return 'Đang chuyển kho';
        default:
          return '';
      }
    },
    hideModalListImei() {
      this.$refs['list-imei-modal'].hide();
    },
    convertPrice,
    getListImei(productId) {
      if (!productId || !this.mainModel.storeId) {
        return;
      }
      let param = {
        productId: productId,
        storeId: this.mainModel.storeId,
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('imeis/get-by-product', paramQuery)
        .then(({ data }) => {
          this.listImeiByProduct = [];
          data.data.forEach((element) => {
            let item = {
              id: element.id,
              imeiCode: element.imeiNo,
              originalPrice: element.originalPrice ? element.originalPrice : 0,
              sellingPrice: element.sellingPrice ? element.sellingPrice : 0,
              status: element.status,
            };
            this.listImeiByProduct.push(item);
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    linkToListImei(product) {
      const { isMainProduct, productId, newProductId, productName } = product;
      const searchingProductId = isMainProduct ? productId : newProductId;
      this.selectingProduct = { ...product };
      this.modalImeiTitle = `Danh sách IMEI sản phẩm:  ${productName}`;
      this.getListImei(searchingProductId);
      this.$refs['list-imei-modal'].show();
    },
    onSelectWarrantyProduct(product) {
      this.selectingProduct = { ...product };
    },
    changeWarrantyProduct(product) {
      const newProduct = cloneDeep(product);
      const updateIndex = this.listBillItem.findIndex(
        (obj) =>
          obj.id == newProduct.oldId &&
          obj.productId == newProduct.oldProductId,
      );
      if (newProduct.newProductType !== 2) {
        this.listBillItem[updateIndex].newImeiCode = '';
      }
      this.listBillItem[updateIndex].newId = newProduct.newId;
      this.listBillItem[updateIndex].newProductId = newProduct.newProductId;
      this.listBillItem[updateIndex].newProductName = newProduct.productName;
      this.listBillItem[updateIndex].newProductCode = newProduct.productCode;
      this.listBillItem[updateIndex].newQuantity = newProduct.quantity;
      this.listBillItem[updateIndex].newProductPrice = newProduct.productPrice;
      this.listBillItem[updateIndex].newTotalPrice = newProduct.totalPrice;
      this.listBillItem[updateIndex].newProductType = newProduct.newProductType;
    },
    onClickCancel() {
      history.back();
    },
    onChangeProduct(product) {
      const data = {
        storeId: this.mainModel.storeId,
        product: product,
      };
      EventBus.$emit('popup-product-common', data);
    },
    getActiveStatus() {
      const listStatus = {
        [this.optionValue.new]: [
          WARRANTY_BILL_STATUS.NEW,
          WARRANTY_BILL_STATUS.PROCESSING,
          WARRANTY_BILL_STATUS.DONE,
        ],
        [this.optionValue.processing]: [
          WARRANTY_BILL_STATUS.PROCESSING,
          WARRANTY_BILL_STATUS.DONE,
        ],
        [this.optionValue.done]: [WARRANTY_BILL_STATUS.DONE],
      };
      this.listStatus = listStatus[this.mainModel.warrantyStatus];

      return listStatus;
    },
    onSelectedTransfer(option) {
      this.mainModel.transferAccountName = option.item.name;
      this.mainModel.transferAccountCode = option.item.code;
      this.mainModel.transferAccountId = option.item.id;
    },
    onInputChangeTransfer(text) {
      const filteredData = this.transferOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredTransferOptions = [...filteredData];
    },
    onSelectCash(option) {
      this.mainModel.cashAccountName = option.item.name;
      this.mainModel.cashAccountCode = option.item.code;
      this.mainModel.cashAccountId = option.item.id;
    },
    onChangeCash(text) {
      const filteredData = this.cashAccOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCashAccOptions = [...filteredData];
    },
    fetchListCashAccountsByStore: async function () {
      if (this.mainModel.storeId !== null) {
        let params = {
          storeId: this.mainModel.storeId,
          type: 1,
        };
        ApiService.query('accountants/getByStore', { params }).then(
          (response) => {
            this.cashAccOptions = [];
            response.data.data.forEach((item) => {
              if (item.level >= 2) {
                const accountant = {
                  id: item.id,
                  name: item.name,
                  code: item.code,
                };
                this.cashAccOptions.push(accountant);
              }
            });
            this.filteredCashAccOptions = cloneDeep(this.cashAccOptions);
          },
        );
      } else {
        const params = {
          type: 1,
        };
        ApiService.query('accountants/getByType', { params }).then(
          (response) => {
            this.cashAccOptions = [];
            response.data.data.forEach((item) => {
              const accountant = {
                id: item.id,
                name: item.name,
                code: item.code,
              };
              this.cashAccOptions.push(accountant);
            });
            this.filteredCashAccOptions = cloneDeep(this.cashAccOptions);
          },
        );
      }
    },
    fetchListTransferAccountantByType: async function () {
      this.listAccSearch = [];
      const params = {
        type: 2,
      };
      ApiService.query('accountants/getByType', { params }).then((response) => {
        this.transferOptions = [];
        response.data.data.forEach((item) => {
          if (item.level >= 2) {
            const accountant = {
              id: item.id,
              name: item.name,
              code: item.code,
            };
            this.transferOptions.push(accountant);
          }
        });
        this.filteredTransferOptions = cloneDeep(this.transferOptions);
      });
    },
    checkTransferAmount() {
      return unMaskPrice(this.mainModel.transferAmount) > 0;
    },
    checkCashAmount() {
      return unMaskPrice(this.mainModel.cashAmount) > 0;
    },
    setEmployeeInfo() {
      const employeeInfo = getData('employee_info');
      if (employeeInfo) {
        const tmpInfo = JSON.parse(employeeInfo);
        this.mainModel.saleId = tmpInfo.id;
        this.mainModel.employeeName = tmpInfo.fullName;
      }
    },
    mapUpdateData(data) {
      this.mainModel.storeName = data.storeName;
      this.mainModel.storeId = data.storeId;
      this.mainModel.saleId = data.saleId;
      this.mainModel.employeeName = data.saleName;
      this.filteredStoreOptions = [];

      if (data.cashAmount > 0) {
        this.mainModel.cashAmount = data.cashAmount;
        this.mainModel.cashAccountName = data.cashAccName;
        this.mainModel.cashAccountId = data.cashAccountId;
        this.mainModel.cashAccountCode = data.cashAccountCode;
      }

      if (data.transferAmount > 0) {
        this.mainModel.transferAmount = data.transferAmount;
        this.mainModel.transferAccountId = data.transferAccountId;
        this.mainModel.transferAccountCode = data.transferAccountCode;
        this.mainModel.transferAccountName = data.transferAccName;
      }
      this.mainModel.warrantyNote = data.warrantyNote;
      this.mainModel.internalNote = data.saleNote;
      const payments = data.payments;
      const transfer = payments.filter(
        (item) => item.paymentType === PAYMENT_TYPE.TRANSFER,
      );
      const cash = payments.filter(
        (item) => item.paymentType === PAYMENT_TYPE.CASH,
      );
      if (cash.length) {
        this.mainModel.cashId = cash[0].id;
        this.mainModel.cashAccountId = cash[0].paymentRefId;
        this.mainModel.cashAmount = cash[0].paymentAmount;
        this.mainModel.cashAccountName = cash[0].paymentName;
        this.mainModel.cashAccountCode = cash[0].paymentRefCode;
      }

      if (transfer.length) {
        this.mainModel.transferId = transfer[0].id;
        this.mainModel.transferAccountId = transfer[0].paymentRefId;
        this.mainModel.transferAmount = transfer[0].paymentAmount;
        this.mainModel.transferAccountName = transfer[0].paymentName;
        this.mainModel.transferAccountCode = transfer[0].paymentRefCode;
      }
    },
    mapBillItems(data) {
      this.listBillItem = [];
      for (const billItem of data.billItems) {
        const { productType } = billItem;
        // neu san pham cua hoa don la san pham chinh hoac imei
        if (
          (productType === PRODUCT_TYPE.PRODUCT ||
            productType === PRODUCT_TYPE.PRODUCT_IMEI) &&
          this.action !== 're-create'
        ) {
          const product = {
            stt: 0,
            id: billItem.id,
            productId: billItem.productId,
            productName: billItem.productName,
            productWebCategory: billItem.productWebCategory,
            productNameVat: billItem.productNameVat,
            code: billItem.productCode,
            productPrice: billItem.productPrice,
            productType: BILL_ITEM_TYPE.PRODUCT,
            returnPrice: 0,
            quantity: billItem.quantity,
            imeiCode: billItem.imeiCode ? billItem.imeiCode : '',
            totalPrice:
              billItem.quantity * billItem.productPrice -
              billItem.discountAmount,
            originalProductType: productType,
            originalQuantity: billItem.quantity,
            isGiftTaken: billItem.isGiftTaken,
            merchantId: billItem.merchantId || 1,
            warrantyReasonId: billItem.warrantyReasonId || null,
            warrantyReasonName: billItem.warrantyReasonName || '',
            belongToProductId: billItem.belongBillDetailId || null,
            newProductId: billItem.newProductId,
            newProductName: billItem.newProductName,
            newProductCode: billItem.newProductCode,
            newProductPrice: billItem.newProductPrice,
            newQuantity: billItem.newQuantity,
            newTotalPrice: billItem.newTotalPrice,
            newImeiCode: billItem.newImeiCode,
            isLostProduct: billItem.isLostProduct,
          };
          this.listBillItem.push(product);
        }
        // neu la san pham com bo
        if (productType === PRODUCT_TYPE.PRODUCT_COMBO) {
          billItem.listProductInCombo.map((element) => {
            const product = {
              stt: 0,
              id: billItem.id,
              productId: element.productId,
              productName: element.productName,
              productWebCategory: element.productWebCategory,
              productNameVat: element.productNameVat,
              code: element.productCode,
              productPrice: element.sellingPrice,
              returnPrice: element.repurchasePrice,
              productType: BILL_ITEM_TYPE.PRODUCT,
              originalProductType: productType,
              originalQuantity: element.quantity,
              quantity: element.quantity,
              imeiCode: element.imeiCode ? element.imeiCode : '',
              totalPrice:
                element.quantity * element.sellingPrice -
                (element.discountAmount || 0),
              isGiftTaken: billItem.isGiftTaken,
              merchantId: billItem.merchantId || 1,
              warrantyReasonId: billItem.warrantyReasonId || null,
              warrantyReasonName: billItem.warrantyReasonName || '',
              belongToProductId: billItem.belongBillDetailId || null,
              newProductId: billItem.newProductId,
              newProductName: billItem.newProductName,
              newProductCode: billItem.newProductCode,
              newProductPrice: billItem.newProductPrice,
              newQuantity: billItem.newQuantity,
              newTotalPrice: billItem.newTotalPrice,
              isLostProduct: billItem.isLostProduct,
            };
            this.listBillItem.push(product);
          });
        }

        if (this.action === 're-create') {
          if (billItem.newProductId) {
            const product = {
              stt: 0,
              id: billItem.id,
              productId: billItem.newProductId,
              productName: billItem.newProductName,
              productWebCategory: billItem.productWebCategory,
              productNameVat: billItem.productNameVat,
              code: billItem.newProductCode,
              productPrice: billItem.newProductPrice,
              productType: BILL_ITEM_TYPE.PRODUCT,
              returnPrice: 0,
              quantity: billItem.newQuantity,
              imeiCode: billItem.newImeiCode,
              totalPrice: billItem.newTotalPrice,
              originalProductType: productType,
              originalQuantity: billItem.newQuantity,
              isGiftTaken: billItem.isGiftTaken,
              merchantId: billItem.merchantId || 1,
              warrantyReasonId: null,
              warrantyReasonName: '',
              belongToProductId: null,
              newProductId: null,
              newProductName: '',
              newProductCode: '',
              newProductPrice: 0,
              newQuantity: 0,
              newTotalPrice: 0,
              newImeiCode: '',
              isLostProduct: false,
            };
            this.listBillItem.push(product);
          }
        }
      }
      this.listBillItem = this.formatBillItems();
    },
    fetchBillById: async function () {
      await ApiService.query(`bills/${this.billNumber}`).then((response) => {
        const { data } = response.data;
        if (!data) {
          return;
        }
        // bill data
        this.billId = data.id;
        this.mainModel.customerName = data.customerName;
        this.mainModel.customerPhone = data.customerPhone;
        this.mainModel.customerAddress = data.customerBillAddress;
        this.mainModel.customerId = data.customerId;
        this.mainModel.warrantyStatus = data.status || WARRANTY_BILL_STATUS.NEW;
        this.checkWarrantyStatus = data.status || WARRANTY_BILL_STATUS.NEW;
        if (this.action === 're-create') {
          this.mainModel.warrantyStatus = WARRANTY_BILL_STATUS.NEW;
          this.checkWarrantyStatus = WARRANTY_BILL_STATUS.NEW;
        }

        this.mainModel.orderId = data.orderId;
        this.mainModel.billType = data.type;
        this.mainModel.isOnTransfer = data.isOnTransfer;
        if (this.action === 'update') {
          this.mapUpdateData(data);
        }
        //  bill items data
        if (data.billItems.length > 0) {
          this.mapBillItems(data);
        }
        this.fetchListCashAccountsByStore();
        this.getActiveStatus();
      });
    },
    formatBillItems() {
      const result = [];
      this.listBillItem.forEach((item, index) => {
        item.stt = index + 1;
        result.push(item);
      });

      return result;
    },
    deleteBillItem(deleteItem) {
      const removeIdex = this.listBillItem.findIndex(
        (obj) =>
          obj.id == deleteItem.id && obj.productId == deleteItem.productId,
      );
      this.listBillItem.splice(removeIdex, 1);
      this.listBillItem.forEach((_, index) => {
        this.listBillItem[index].stt = index + 1;
      });
    },
    onInputWarrantyReason(data) {
      this.listBillItem.map((item) => {
        if (item.id === data.productItem.id) {
          item.warrantyReasonName = data.text;
        }
      });
    },
    onSelectWarranty(data) {
      this.listBillItem.map((item) => {
        if (item.id === data.productItem.id) {
          item.warrantyReasonId = data.warrantyReasonId;
          item.warrantyReasonName = data.warrantyReasonName;
        }
      });
    },
    createWarrantyBill: function () {
      const listPro = this.listBillItem.map((element) => {
        return {
          id: element.id,
          productId: element.productId,
          productName: element.productName,
          productPrice: element.productPrice
            ? unMaskPrice(element.productPrice)
            : 0,
          quantity: element.quantity,
          discountAmount: element.discount ? unMaskPrice(element.discount) : 0,
          imeiCode: element.imeiCode,
          productType: element.productType,
          originalProductType: element.originalProductType,
          originalQuantity: element.originalQuantity,
          repurchasePrice: element.returnPrice
            ? unMaskPrice(element.returnPrice)
            : 0,
          isGiftTaken: element.isGiftTaken,
          note: '',
          merchantId: element.merchantId || 1,
          warrantyReasonId: element.warrantyReasonId,
          warrantyReasonName: element.warrantyReasonName,
          belongBillItemId: element.belongToProductId || null,
          isLostProduct: element.isLostProduct,
        };
      });
      const data = {
        customerMobile: this.mainModel.customerPhone,
        customerName: this.mainModel.customerName,
        customerAddress: this.mainModel.customerAddress,
        customerId: this.mainModel.customerId,
        status: this.mainModel.warrantyStatus,
        customerBankName: this.mainModel.customerBankName,
        customerTransferNo: this.mainModel.customerTransferNo,
        storeId: this.mainModel.storeId,
        warrantyNote: this.mainModel.warrantyNote,
        saleId: this.mainModel.saleId,
        saleNote: this.mainModel.internalNote,
        cashId: this.mainModel.cashId,
        cashAccountId: this.mainModel.cashAccountId,
        cashAccountName: this.mainModel.cashAccountName,
        cashAccountCode: this.mainModel.cashAccountCode,
        cashAmount: this.mainModel.cashAmount
          ? unMaskPrice(this.mainModel.cashAmount)
          : 0,
        transferAccountId: this.mainModel.transferAccountId,
        transferAccountName: this.mainModel.transferAccountName,
        transferAccountCode: this.mainModel.transferAccountCode,
        transferAmount: this.mainModel.transferAmount
          ? unMaskPrice(this.mainModel.transferAmount)
          : 0,
        employeeName: this.mainModel.employeeName,
        warrantyErrorBy: this.warrantyErrorBy,
        listBillItem: listPro,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.post(`/bills/warranty-bill/${this.billId}`, data)
          .then((response) => {
            const { status, message } = response.data;
            if (status === 1) {
              this.makeToastSuccess(message);
              this.$router.push({
                path: '/warranty-bill',
              });
            } else {
              this.makeToastFailure(message);
              this.isNew = true;
            }
          })
          .catch(({ response }) => {
            const { status, message } = response.data;
            if (status === 0) {
              this.makeToastFailure(message);
              this.isNew = true;
            }
          });
      }
    },
    updateWarrantyBill: function () {
      const listPro = this.listBillItem.map((element) => {
        return {
          id: element.id,
          productId: element.productId,
          productName: element.productName,
          productPrice: element.productPrice
            ? unMaskPrice(element.productPrice)
            : 0,
          quantity: element.quantity,
          discountAmount: element.discount ? unMaskPrice(element.discount) : 0,
          imeiCode: element.imeiCode,
          productType: element.productType,
          repurchasePrice: element.returnPrice
            ? unMaskPrice(element.returnPrice)
            : 0,
          isGiftTaken: element.isGiftTaken,
          note: '',
          merchantId: element.merchantId || 1,
          warrantyReasonId: element.warrantyReasonId,
          warrantyReasonName: element.warrantyReasonName,
          belongBillItemId: element.belongToProductId || null,
          newProductId: element.newProductId,
          newProductType: BILL_ITEM_TYPE.RETURN_WARRANTY,
          newProductName: element.newProductName,
          newProductCode: element.newProductCode,
          newProductPrice: element.newProductPrice,
          newTotalPrice: element.newTotalPrice,
          newQuantity: element.newQuantity,
          newImeiCode: element.newImeiCode,
          isLostProduct: element.isLostProduct,
        };
      });

      const data = {
        storeId: this.mainModel.storeId,
        customerId: this.mainModel.customerId,
        customerName: this.mainModel.customerName,
        customerMobile: this.mainModel.customerPhone,
        customerAddress: this.mainModel.customerAddress,
        warrantyNote: this.mainModel.warrantyNote,
        saleNote: this.mainModel.internalNote,
        saleId: this.mainModel.saleId,
        transferAmount: this.mainModel.transferAmount
          ? unMaskPrice(this.mainModel.transferAmount)
          : 0,
        transferId: this.mainModel.transferId,
        transferAccountId: this.mainModel.transferAccountId,
        transferAccountName: this.mainModel.transferAccountName,
        transferAccountCode: this.mainModel.transferAccountCode,
        customerTransferNo: this.mainModel.customerTransferNo,
        customerBankName: this.mainModel.customerBankName,
        cashAmount: this.mainModel.cashAmount
          ? unMaskPrice(this.mainModel.cashAmount)
          : 0,
        cashId: this.mainModel.cashId,
        cashAccountId: this.mainModel.cashAccountId,
        cashAccountName: this.mainModel.cashAccountName,
        cashAccountCode: this.mainModel.cashAccountCode,
        status: this.mainModel.warrantyStatus,
        listBillItem: listPro,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.put(`/bills/warranty-bill/${this.billNumber}`, data)
          .then((response) => {
            const { status, message } = response.data;
            if (status === 1) {
              this.makeToastSuccess(message);
              this.$router.push({
                path: '/warranty-bill',
              });
            } else {
              this.makeToastFailure(message);
              this.isNew = true;
            }
          })
          .catch((err) => {
            if (!err.response) {
              // network error
              this.makeToastFailure(err.$error);
              this.isNew = true;
            } else {
              const message = err.response.data.message;
              this.makeToastFailure(message);
              this.isNew = true;
            }
          });
      }
    },
    validateWarrantyReason() {
      let result = { status: true, message: '' };
      this.listBillItem.map((item) => {
        if (!item.warrantyReasonName) {
          result.status = false;
          result.message = `Vui lòng nhập lý do bảo hành cho sản phẩm ${item.productName}`;
          return;
        }
        const isValidReason = this.warrantyReasons.find(
          (reason) => reason.name === item.warrantyReasonName,
        );
        if (!isValidReason) {
          result.status = false;
          result.message = `Lý do ${item.warrantyReasonName} không hợp lệ`;
          return;
        }
      });

      return result;
    },
    validatePayment() {
      if (unMaskPrice(this.mainModel.cashAmount) > 0) {
        if (
          !isEmptyAccountingAccount(
            this.mainModel.cashAmount,
            this.mainModel.cashAccountName,
          )
        ) {
          return false;
        }
      }
      if (unMaskPrice(this.mainModel.transferAmount) > 0) {
        if (
          !isEmptyAccountingAccount(
            this.mainModel.transferAmount,
            this.mainModel.transferAccountName,
          )
        ) {
          return false;
        }
      }

      return true;
    },
    debounceSubmit: debounce(function () {
      this.onSubmit();
    }, TIME_TRIGGER),
    validate() {
      const result = {
        valid: true,
        message: '',
      };
      //  validate list product
      if (!this.listBillItem.length) {
        result.message = 'Vui lòng nhập sản phẩm';
        result.valid = false;
      }
      if (!this.mainModel.customerPhone) {
        result.message = 'Vui lòng nhập số điện thoại';
        result.valid = false;
      }
      if (!this.mainModel.employeeName) {
        result.message = 'Vui lòng nhập nhân viên bảo hành';
        result.valid = false;
      }
      if (!this.mainModel.customerName) {
        result.message = 'Vui lòng nhập họ tên khách hàng';
        result.valid = false;
      }
      //  validate accounting account
      const validatePayment = this.validatePayment();
      if (!validatePayment) {
        result.message = 'Dữ liệu không hợp lệ';
        result.valid = false;
      }
      //  validate warranty reason
      const validateWarrantyReason = this.validateWarrantyReason();
      if (!validateWarrantyReason.status) {
        result.message = validateWarrantyReason.message;
        result.valid = false;
      }

      return result;
    },
    onSubmit() {
      const validationResult = this.validate();
      if (!validationResult.valid) {
        return this.makeToastFailure(validationResult.message);
      }
      if (this.action === 'update') {
        this.updateWarrantyBill();
      } else {
        this.createWarrantyBill();
      }
    },
    fetchWarrantyReasons: async function () {
      const param = {
        page: 1,
        pageSize: 10000,
      };
      const paramQuery = {
        params: param,
      };
      await ApiService.query('warranty-reason-config', paramQuery).then(
        (response) => {
          const { result } = response.data.data;
          result.forEach((reason) => {
            const item = {
              id: reason.id,
              name: reason.name,
              internalCateId: reason.internalCateId,
            };
            this.warrantyReasons.push(item);
          });
        },
      );
    },
  },
};
</script>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5;
}
</style>

<style lang="scss" scoped>
@import 'src/assets/sass/components/_variables.bootstrap.scss';
.upsert-warranty-bill {
  // common
  $small-clumn-width: 7%;
  $tiny-clumn-width: 5%;
  $brown-color: brown;

  .column-quantity {
    width: $small-clumn-width;
  }

  .column-import-stock {
    width: $small-clumn-width;
  }

  .column-swap-product {
    width: $small-clumn-width;
  }

  .form-group label {
    font-weight: $font-weight-bold;
  }

  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }

  .input-style {
    border: 1px solid #ced4da;
  }

  .header-table {
    color: rgb(24, 28, 50);
  }

  .text-payment {
    font-size: $h5-font-size;
    color: $brown-color;
    font-weight: $font-weight-bold;
  }

  .text-list-product {
    font-size: $h5-font-size;
    color: $brown-color;
    font-weight: $font-weight-bold;
  }
}
</style>
